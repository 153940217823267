import { apiCms, apiWms } from './index';
import { notification } from 'antd';
import { cleanObject } from 'helper';
import { envs } from 'shared/utils/env';
const { cmsBaseUrl } = envs;
const { wmsBaseUrl } = envs;

const getWSINData = async ({ current = 1, pageSize = 10, sortField = 'id', sortOrder = 'ASC', companyId, search = '', filter }) => {
	return await apiWms.post('/wsin/fetch', {
		where: {
			companyId,
			...(search?.length > 0 && { search }),
			searchFilter: cleanObject({ ...filter }, ['', null, undefined])
		},
		pagination: {
			records: pageSize,
			pageNumber: current
		},
		sortBy: {
			order: sortField,
			by: sortOrder
		}
	});
};

const getWsinForAutoComplete = async (searchText, searchType, companyId) => {
	const {
		data: { wsins }
	} = await apiWms.post('/wsin/fetch', {
		where: {
			companyId,
			searchFilter: {
				searchOptionType: 'wsin',
				searchValue: searchText
			}
		}
	});

	let newOptions = [];
	wsins.forEach((item) => newOptions.push({ value: item.wsin, key: item.id }));

	return newOptions;
};

const getCatalogForAutoComplete = async (searchText, searchType, companyId) => {
	const {
		data: { catalogue }
	} = await apiCms.post('/find/catalogue', {
		where: {
			companyId,
			searchFilter: {
				searchOptionType: 'productTitle',
				searchValue: searchText
			}
		},

		pagination: {
			records: 10,
			pageNumber: 1
		},
		sortBy: {
			order: 'id',
			by: 'ASC'
		}
	});
	let newOptions = [];
	catalogue.forEach((item) => newOptions.push({ value: item.productTitle, key: item.id }));

	return newOptions;
};

const getCompanyCatalog = (
	{ current = 1, pageSize = 10, sortField = 'id', sortOrder = 'ASC', shopType, isAdminView, companyId, search = '', filter },
	selectedCompanyIds
) => {
	return apiCms.post('/find/catalogue', {
		where: {
			...(isAdminView && { companyId }),
			shopType,
			searchFilter: cleanObject({ ...filter }, ['', null, undefined])
		},
		pagination: {
			records: pageSize,
			pageNumber: current
		},
		sortBy: {
			order: sortField,
			by: sortOrder
		},
		companyIds: selectedCompanyIds
	});
};

const updateCatalog = (catalogId, data) => {
	return apiCms.put(`/catalogue/${catalogId}`, data);
};

const createCatalog = (data) => {
	return apiCms.post('/catalogue', data);
};

const getCatalogueById = (catalogId, isAdminView) => {
	return apiCms.get(`/catalogue/${catalogId}`);
};

const syncCatalogue = (shopType) => {
	return apiCms.get(`/syncCatalogue/${shopType}`);
};

const getHSNCodes = (searchQuery) => {
	return apiCms.get(`/hsn_code?searchQuery=${searchQuery}`);
};

const downloadCataloguesCSV = (shopType, selectedCompanyIds, companyId, selectedCatalogueIds) => {
	try {
		// For wms panel
		if (selectedCompanyIds) {
			const url = new URL(`${wmsBaseUrl}/catalogue/csv/${shopType}/${selectedCompanyIds}?appType=${envs.appType}`);
			window.open(url);
		} else if (companyId) {
			// for admin panel
			const url = new URL(`${cmsBaseUrl}/catalogue/csv/${companyId}/${JSON.stringify(selectedCatalogueIds)}?appType=${envs.appType}`);
			window.open(url);
		} else {
			// For Seller panel
			const url = new URL(`${cmsBaseUrl}/catalogue/csv/${shopType}?appType=${envs.appType}`);
			window.open(url);
		}
	} catch (err) {
		notification.error({
			message: 'Download CSV Error',
			description: 'Please try again after some time.',
			placement: 'topRight'
		});
	}
};

export {
	getCompanyCatalog,
	updateCatalog,
	createCatalog,
	getCatalogueById,
	syncCatalogue,
	getHSNCodes,
	downloadCataloguesCSV,
	getWSINData,
	getWsinForAutoComplete,
	getCatalogForAutoComplete
};
