import { Tag, Progress, Button, Modal } from 'antd';
import dayjs from 'dayjs';
import { tagColor, taskStatus } from './constants';
import { useState } from 'react';

export default function useColumns(progressMap: any) {
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [modalContent, setModalContent] = useState(null);

	const handleViewMoreClick = (description: any) => {
		setModalContent(description);
		setIsModalVisible(true);
	};
	return [
		{
			title: 'Name',
			dataIndex: 'name',
			render: (name: string) => <div className="text-center">{name}</div>
		},
		{
			title: 'Status',
			dataIndex: 'status',
			render: (status: taskStatus, record: any) => (
				<div className="text-center">
					{progressMap && progressMap[record.taskId] ? (
						progressMap[record.taskId] === 100 ? (
							<Tag color={tagColor[taskStatus.COMPLETED]}>{taskStatus.COMPLETED}</Tag>
						) : (
							<Progress type="circle" percent={Math.round(progressMap[record?.taskId])} width={40} />
						)
					) : (
						<Tag color={tagColor[status]}>{status}</Tag>
					)}
				</div>
			)
		},
		{
			title: 'Started At',
			dataIndex: 'startedAt',
			render: (startedAt: string) => <div className="text-center">{dayjs(startedAt).format('MMM D, YYYY h:mm A')}</div>,
			width: 150,
			sorter: true
		},
		{
			title: 'Ended At',
			dataIndex: 'endedAt',
			render: (endedAt: string) => <div className="text-center">{endedAt ? dayjs(endedAt).format('MMM D, YYYY h:mm A') : '-'}</div>,
			width: 150,
			sorter: true
		},
		{
			title: 'Remark',
			dataIndex: 'description',
			render: (description: string, record: any) => (
				<>
					<div className="text-center">
						{(description && description?.slice(0, 20)) ||
							(progressMap && progressMap?.[`message-${record?.taskId}`]?.slice(0, 20)) ||
							'-'}
					</div>

					{((description && description.length > 20) || (progressMap && progressMap.length > 20)) && (
						<Button type="link" onClick={() => handleViewMoreClick(description)} size="small" style={{ position: 'relative' }}>
							View More
						</Button>
					)}
					{(description || progressMap?.[`message-${record?.taskId}`]) && (
						<Modal
							visible={isModalVisible}
							style={{ position: 'absolute', top: 20, left: '50%', backgroundColor: 'transparent' }}
							onCancel={() => {
								setIsModalVisible(false);
								setModalContent(null);
							}}
							onOk={() => {
								setIsModalVisible(false);
								setModalContent(null);
							}}
							title="order id information"
						>
							{modalContent}
						</Modal>
					)}
					{/** || progressMap?.[`message-${record?.taskId}`] */}
				</>
			)
		},
		{
			title: 'Download Link',
			dataIndex: 'downloadLink',
			render: (downloadLink: string) => (
				<div className="text-center">
					{downloadLink ? (
						<a
							target="_blank"
							rel="noreferrer"
							href={`https://wherehouse-prod-content.s3.ap-south-1.amazonaws.com/${downloadLink}`}
						>
							Download Link
						</a>
					) : (
						<span>-</span>
					)}
				</div>
			)
		}
	];
}
