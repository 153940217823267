import dayjs from 'dayjs';
export const searchOptions = [
	{ value: 'shopOrderId', label: 'Order ID' },
	{ value: 'deliveryAwb', label: 'AWB' },
	{ value: 'invoiceId', label: 'Invoice ID' },
	{ value: 'deliveryPartnerId', label: 'Delivery Partner' },
	{ value: 'paymentMode', label: 'Payment Mode' },
	{ value: 'shopType', label: 'Shop Type' },
	{ value: 'shippingFirstName', label: 'Customer name' },
	{ value: 'shippingPhone', label: 'Customer phone' },
	{ value: 'shippingState', label: 'Delivery State' },
	{ value: 'shippingPincode', label: 'Delivery Pincode' },
	{ value: 'pickupPincode', label: 'Pickup Pincode' },
	{ value: 'pickupState', label: 'Pickup State' },
	{ value: 'sku', label: 'Sku' },
	{ value: 'shipper', label: 'Shipper' },
	{ value: 'returnAwb', label: 'Reverse Awb' },
	{ value: 'isb2bOrder', label: 'B2B Order (0/1)' },
	{ value: 'isD2ROrder', label: 'D2R Order (0/1)' },
	{ value: 'creditDebitNoteId', label: 'CN available (0/1)' },
	{ value: 'd2rPriceEdited', label: 'D2R Price Edited (0/1)' }
];

export const defaultFilter = {
	startDate: dayjs().subtract(30, 'day'),
	endDate: dayjs(),
	searchOptionType: searchOptions[0].value
};

export const jsxElementKeys = {
	STATUS_FILTER: 'statusFilter',
	SEARCH_CONTAINER: 'searchContainer',
	TAG_FILTER: 'tagFilter',
	AUTOCOMPLETE_COMPANY: 'autocompleteCompany',
	BEAT_BUTTON_GROUP: 'beatButtonGroup',
	BULK_ACTION: 'bulkAction',
	MULTIPLE_FILTER: 'multipleFilter',
	DOWNLOAD_ORDERS: 'downloadOrder'
};

export const DEFAULT_IMAGE_IN_CATALOGUE = 'https://wherehouse-prod-content.s3.ap-south-1.amazonaws.com/assets/default_product_img.svg';
