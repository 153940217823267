import { Button } from 'antd';
import dayjs from 'dayjs';

import { envs } from '../../../../shared/utils/env';
import { isSuperAdmin } from '../../../../shared/utils/extra';
import { jsxElementKeys } from '../../../../shared/utils/constant';
import { DebouncedCompactSearch } from '../../../../shared/components/DebouncedCompactSearch/DebouncedCompactSearch';

const { omsBaseUrl, appType } = envs;
interface IRetailersFilter {
	profile: any;
	setPageConfigDefault: any;
	setFilter: any;
	filter: any;
	setIsMappingModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const searchOptions: any = [
	{ value: 'storeName', label: 'store name' },
	{ value: 'phone', label: 'phone' },
	{ value: 'isActive', label: 'isActive (0/1)' }
];

export interface ICommonFilterRetail {
	searchOptionType: string;
	searchValue?: string;
}
export const defaultFilter: ICommonFilterRetail = {
	searchOptionType: searchOptions[0].value
};

export const retailersFilter = ({ profile, setPageConfigDefault, setFilter, filter, setIsMappingModalVisible }: IRetailersFilter) => {
	return {
		row1: [],
		row1children: [
			<div key={jsxElementKeys.SEARCH_CONTAINER}>
				<DebouncedCompactSearch
					defaultSearchOptionType={filter.searchOptionType}
					searchOptions={searchOptions}
					setFilter={setFilter}
					setDefault={setPageConfigDefault}
				>
					<span>Search By:</span>
				</DebouncedCompactSearch>
			</div>
		],
		row2: [
			<div style={{ display: 'flex' }}>
				<div key={'3'} style={{ display: 'flex', gap: '1rem', marginRight: '10px' }}>
					<Button type="primary" onClick={handleDownloadMappings}>
						Download Mappings
					</Button>
				</div>
				<div key={'3'} style={{ display: 'flex', gap: '1rem', marginRight: '10px' }}>
					<Button type="primary" onClick={() => setIsMappingModalVisible(true)}>
						Map Duplicates
					</Button>
				</div>
				<div key={'3'} style={{ display: 'flex', gap: '1rem' }}>
					<Button type="primary" onClick={() => handleDownloadRetailers(profile)}>
						Download Retailers
					</Button>
				</div>
			</div>
		]
	};
};

export const retailerColumn = (setIsRetailerModalVisible: Function, setRetailerId: Function) => [
	{
		title: 'Created At',
		render: (data: any) => {
			return (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>{dayjs(new Date(data?.createdAt)).format('MMM D, YYYY ')}</span>
					<span>{dayjs(new Date(data?.createdAt)).format('h:mm A')}</span>
				</div>
			);
		},
		width: 60
	},
	{
		title: 'Name',
		render: (data: any) => (
			<Button
				type="link"
				onClick={() => {
					setIsRetailerModalVisible(true);
					setRetailerId(data?.id);
				}}
			>
				<span>{`${data?.firstName} ${data?.lastName}`}</span>
			</Button>
		)
	},
	{
		title: 'Address',
		render: (data: any) => (
			<span>{`${data?.addressLine1}, ${data.addressLine2}, ${data?.city}, ${data.state}, ${data.country}, ${data.pincode}`}</span>
		)
	},
	{
		title: 'Phone',
		render: (data: any) => <span>{data?.phone}</span>,
		width: 60
	},
	{
		title: 'GSTIN',
		render: (data: any) => <span>{data?.GSTIN}</span>,
		width: 70
	},
	{
		title: 'Email',
		render: (data: any) => <span>{data?.email}</span>,
		width: 80
	}
];

export function handleDownloadMappings() {
	console.log('download retailers');
	const url = new URL(`${omsBaseUrl}/download/retailer/mapping`);
	window.open(url);
}

export function handleDownloadRetailers(profile: any) {
	if (!isSuperAdmin(profile)) {
		window.alert('coming soon!!');
		return;
	}

	const url = new URL(`${omsBaseUrl}/csv/d2r/retailers?appType=${appType}`);
	window.open(url);
}

export const RETAILER_MAPPING_NOTE = 'NOTE: Orders from the previous retailer will be pointed to the new Primary Retailer.';
