import { Button, DatePicker, Divider, Tooltip } from 'antd';
import { BarcodeOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { DebouncedCompactSearch } from 'shared/components/DebouncedCompactSearch/DebouncedCompactSearch';
import { envs } from 'shared/utils/env';
import { searchOptions } from './constants';
import exportSvg from 'shared/svgs/export_svg';
const { cmsBaseUrl } = envs;

export const sidebarUtil = (warehouses = []) => {
	return {
		sideBarMenu: warehouses.map(({ warehouseId, profile: { name } }) => ({ key: warehouseId, label: name, icon: 'allSvgIcon' }))
	};
};

export const topFilterUtil = ({
	filter,
	setFilter,
	setPageConfigDefault,
	setBulkInventoryUploadModal,
	setInventorySnapshotDate,
	inventorySnapshotDate,
	isWMS,
	sidebarMenuItem,
	handleScanAddInventoryButton,
	setShouldShowRequestEmailModal,
	referenceCompanyId
}) => {
	return {
		row1: [], // required to render row1Children
		row1children: [
			<div>
				<DebouncedCompactSearch
					defaultSearchOptionType={filter.searchOptionType}
					searchOptions={searchOptions}
					setFilter={setFilter}
					setDefault={setPageConfigDefault}
				>
					<span>Search By:</span>
				</DebouncedCompactSearch>
			</div>
		],
		row2: [
			<div style={{ display: 'flex', width: '100%', alignItems: 'baseline' }}>
				<div>
					<Tooltip placement="leftTop" title="Inventory snapshot at that given date (optional)">
						<DatePicker
							style={{ margin: '0 0.5rem' }}
							onChange={(date) => setInventorySnapshotDate(dayjs(date)?.endOf('day').format())}
							placeholder={'As on date'}
						/>
					</Tooltip>
					<Button
						type="primary"
						onClick={() => {
							// reference id is warehouse id in case of brand panel and companyId for wms panel
							const referenceId = isWMS ? referenceCompanyId.join(',') : sidebarMenuItem;
							const snapshotDate = inventorySnapshotDate ? `/${inventorySnapshotDate}` : '';

							if (!snapshotDate) {
								window.open(
									`${cmsBaseUrl}/inventory/bulk/csv/${referenceId}${snapshotDate}?appType=${envs.appType}`,
									'_blank'
								);
								return;
							}

							setShouldShowRequestEmailModal(true);
						}}
					>
						<span>{exportSvg}</span>
						<span style={{ marginLeft: '.4rem', fontSize: '0.9rem' }}>Export</span>
					</Button>
				</div>
				{isWMS && (
					<>
						<Divider style={{ backgroundColor: 'gray' }} type="vertical" />
						<Button
							style={{ display: 'flex', alignItems: 'center' }}
							onClick={handleScanAddInventoryButton}
							type="primary"
							icon={<BarcodeOutlined />}
						>
							Scan And Add Inventory
						</Button>
					</>
				)}

				{isWMS ? (
					<>
						<Divider style={{ backgroundColor: 'gray' }} type="vertical" />
						<Button type="primary" onClick={() => setBulkInventoryUploadModal(true)} style={{ marginRight: '.4rem' }}>
							<span>Add Inventory</span>
						</Button>
					</>
				) : null}
			</div>
		]
	};
};
