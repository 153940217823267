import { Result, Button, Row, Col, Divider } from 'antd';

export const ErrorComponent = ({ previewConfigName, history, errorsArray, successArray }) => {
	console.log(previewConfigName, history, errorsArray, successArray);
	const errorFieldMapWithPreviewConfigName = {
		orders: (data) => (
			<>
				<b>ID:</b> {data.orderId}
			</>
		),
		'cod-remittance': (data) => (
			<>
				<b>AWB:</b> {data.awb}
			</>
		),
		'shipping-charge-adjustment': (data) => (
			<>
				<b>AWB:</b> {data.awb}
			</>
		),
		'd2r-beats': (data) => {
			<>
				<b>Beat:</b> {data.name}
			</>;
		}
	};

	return (
		<Result
			status="error"
			title={`Following errors encountered while saving orders; ${String(previewConfigName).replace('-', ' ')}`}
			extra={[
				<>
					<Row style={{ marginBottom: '40px' }}>
						<Col span={24}>
							<Col span={24}>
								<b style={{ color: 'red' }}>Failed {String(previewConfigName).replace('-', ' ')}: </b>
							</Col>
							<div style={{ display: 'inline-block', width: '100%', maxWidth: '1000px', marginTop: '1rem' }}>
								{errorsArray.map((data) => {
									return (
										<Row>
											<Col span={6}>{errorFieldMapWithPreviewConfigName[previewConfigName](data)}</Col>
											<Col span={18}>
												<b>Error:</b> {data.message}
											</Col>
										</Row>
									);
								})}
							</div>
						</Col>
						<Divider />
						<Col span={24}>
							<Col span={24}>
								<b style={{ color: 'green' }}>{successArray?.length ? 'Successful Orders:' : ''} </b>
							</Col>
							{successArray.map((data) => {
								return (
									<>
										<Col span={24}>
											<b>ID:</b> {data.orderId}
										</Col>
									</>
								);
							})}
						</Col>
					</Row>

					<Button type="primary" key="console" onClick={history.goBack}>
						{`Go to ${previewConfigName} page`}
					</Button>
				</>
			]}
		/>
	);
};
