import { Button, Tag } from 'antd';

export default function d2rInvoiceColumns(setShowBeatsInfoSlider: any) {
	return [
		{
			title: 'Generated On',
			dataIndex: 'invoice',
			render: (invoice: any) => <div className="text-center">{new Date(invoice.createdAt).toLocaleDateString()}</div>,
			width: 150,
			sorter: true
		},
		{
			title: 'Due Date',
			dataIndex: 'd2rDueDate',
			render: (d2rDueDate: any) => <div className="text-center">{new Date(d2rDueDate).toLocaleDateString()}</div>,
			width: 150,
			sorter: true
		},
		{
			title: 'Invoice Id',
			render: (data: any) => (
				<Button
					type="link"
					onClick={() => {
						window.open(`https://wherehouse-prod-content.s3.ap-south-1.amazonaws.com/${data?.invoiceUrl}`, '_blank');
					}}
				>
					{data.invoiceId}
				</Button>
			),
			width: 200,
			sorter: (a: any, b: any) => a.invoiceId.localeCompare(b.invoiceId)
		},
		{
			title: 'Total Amount',
			dataIndex: 'finalPrice',
			render: (finalPrice: number, data: any) => (
				<div className="text-center">
					<p>₹{Number(finalPrice).toFixed(2)}</p>
					<Tag color="blue">{data.paymentMode.toUpperCase()}</Tag>
				</div>
			)
		},
		{
			title: 'Pending Amount',
			render: (data: any) => (
				<>
					<Button
						type="link"
						onClick={() => {
							setShowBeatsInfoSlider(data.id);
						}}
					>
						₹{Number(data?.finalPrice - data?.amountPaid).toFixed(2)}
					</Button>
					{data.finalPrice - data.amountPaid >= 1 ? <Tag color="red">Pending</Tag> : <Tag color="green">Paid</Tag>}
				</>
			)
		},
		{
			title: 'Order Status',
			dataIndex: 'status',
			render: (status: number) => <div className="text-center">{status}</div>
		},
		{
			title: 'Retailer Name',
			dataIndex: 'shippingD2RAddress',
			render: (shippingD2RAddress: any) => (
				<div className="text-center">{shippingD2RAddress?.firstName + ' ' + shippingD2RAddress?.lastName} </div>
			)
		},
		{
			title: 'Salesman Name',
			dataIndex: 'salesman',
			render: (salesman: any) => <div className="text-center">{salesman?.firstName || ''}</div>
		},
		{
			title: 'Salesman Phone',
			dataIndex: 'salesman',
			render: (salesman: any) => <div className="text-center">{salesman?.phone || ''}</div>
		}
	];
}
