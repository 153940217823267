import { Button } from 'antd';
import { FormLayout } from 'shared/components/FormLayout';
import errorHandler from 'shared/utils/errorHandler';
import { AssignCluster } from './AssignCluster';
import { Beats } from './Beats';
import { ClusterOwner } from './ClusterOwner';
import { Clusters } from './Clusters';
import { CLUSTER_MAPPING, DRIVER_STATUS_MAPPING } from './constants';
import { CreateCluster } from './CreateCluster';
import { CreateClusterOwner } from './CreateClusterOwner';
import { CreateDriver } from './CreateDriver';
import { CreateWarehouse } from './CreateWarehouse';
import { CreateWarehouseUser } from './CreateWarehouseUser';
import { Drivers } from './Drivers';
import styles from './index.module.scss';
import { Orders } from './Orders';
import { Trips } from './Trips';
import { Warehouses } from './Warehouses';
import { WarehouseUser } from './WarehouseUser';

const sideBarArray = [
	{
		label: 'Orders',
		parent: 'Orders',
		key: 1,
		icon: 'allSvgIcon'
	},
	{
		label: 'Trips',
		parent: 'Trips',
		key: 2,
		icon: 'allSvgIcon'
	},
	{
		label: 'Beats',
		parent: 'Trips',
		key: 14,
		icon: 'allSvgIcon'
	},
	{
		label: 'Drivers',
		parent: 'Drivers',
		key: 3,
		icon: 'allSvgIcon'
	},
	{
		label: 'Create Driver',
		parent: 'Drivers',
		key: 4,
		icon: 'allSvgIcon'
	},
	{
		label: 'Clusters',
		parent: 'Clusters',
		key: 5,
		icon: 'allSvgIcon'
	},
	{
		label: 'Cluster Owners',
		parent: 'Clusters',
		key: 6,
		icon: 'allSvgIcon'
	},
	{
		label: 'Assign Cluster',
		parent: 'Clusters',
		key: 7,
		icon: 'allSvgIcon'
	},
	{
		label: 'Create Cluster',
		parent: 'Clusters',
		key: 8,
		icon: 'allSvgIcon'
	},
	{
		label: 'Create Cluster Owner',
		parent: 'Clusters',
		key: 9,
		icon: 'allSvgIcon'
	},
	{
		label: 'Create Warehouse user',
		parent: 'Warehouse',
		key: 10,
		icon: 'allSvgIcon'
	},
	{
		label: 'Warehouses',
		parent: 'Warehouse',
		key: 11,
		icon: 'allSvgIcon'
	},
	{
		label: 'Create Warehouse',
		parent: 'Warehouse',
		key: 12,
		icon: 'allSvgIcon'
	},
	{
		label: 'Warehouse User',
		parent: 'Warehouse',
		key: 13,
		icon: 'allSvgIcon'
	}
];

export const sidebarUtil = {
	sideBar: [
		{
			sectionName: 'Orders',
			componentList: sideBarArray.filter((item) => item.parent === 'Orders')
		},
		{
			sectionName: 'Trips',
			componentList: sideBarArray.filter((item) => item.parent === 'Trips')
		},
		{
			sectionName: 'Drivers',
			componentList: sideBarArray.filter((item) => item.parent === 'Drivers')
		},
		{
			sectionName: 'Clusters',
			componentList: sideBarArray.filter((item) => item.parent === 'Clusters')
		},
		{
			sectionName: 'Warehouse',
			componentList: sideBarArray.filter((item) => item.parent === 'Warehouse')
		}
	]
};

export function getLightningSubPageMapper({ clusters, setClusters, drivers, setDrivers, warehouses }) {
	return {
		1: <Orders />,
		2: <Trips />,
		3: <Drivers clusters={clusters} />,
		4: <CreateDriver clusters={clusters} setDrivers={setDrivers} />,
		5: <Clusters clusters={clusters} />,
		6: <ClusterOwner />,
		7: <AssignCluster clusters={clusters} drivers={drivers} setDrivers={setDrivers} />,
		8: <CreateCluster setClusters={setClusters} />,
		9: <CreateClusterOwner clusters={clusters} />,
		10: <CreateWarehouseUser warehouses={warehouses} />,
		11: <Warehouses warehouses={warehouses} />,
		12: <CreateWarehouse />,
		13: <WarehouseUser />,
		14: <Beats />
	};
}

export const addressFields = [
	{
		label: 'Email',
		placeholder: 'noreply@wherehouse.io',
		name: ['address', 'email'],
		id: 'email',
		required: false
	},
	{
		label: 'Address Line 1',
		placeholder: '',
		name: ['address', 'addressLine1'],
		id: 'addressLine1',
		required: true
	},
	{
		label: 'Address Line 2',
		placeholder: '',
		name: ['address', 'addressLine2'],
		id: 'addressLine2'
	},
	{
		label: 'City',
		placeholder: 'Mumbai',
		name: ['address', 'city'],
		id: 'city',
		required: true
	},
	{
		label: 'State',
		placeholder: 'Maharashtra',
		name: ['address', 'state'],
		id: 'state',
		required: true
	},
	{
		label: 'Pincode',
		placeholder: '110004',
		name: ['address', 'pincode'],
		id: 'pincode',
		required: true
	},
	{
		label: 'Country',
		placeholder: 'India',
		name: ['address', 'country'],
		id: 'country',
		required: true
	}
];

export function createUserForm({ formTitle, onSave, clusters, isWarehouseUser = false, warehouses }) {
	const generateFormFields = () => {
		let formFields = [
			{
				label: 'Name',
				placeholder: 'Yash',
				name: 'name',
				id: 'name',
				required: true
			},
			{
				label: 'Phone',
				placeholder: '9999977777',
				name: 'phone',
				id: 'phone',
				required: true
			},
			{
				label: `${isWarehouseUser ? 'Warehouse' : 'Cluster'}`,
				placeholder: `Select a ${isWarehouseUser ? 'warehouse' : 'cluster'}`,
				fieldType: 'select',
				name: `${isWarehouseUser ? 'warehouseId' : 'clusterId'}`,
				id: `${isWarehouseUser ? 'warehouseId' : 'clusterId'}`,
				required: true,
				showSearch: true,
				optionFilterProp: 'children',
				filterOption: (input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
				options: isWarehouseUser ? warehouses : clusters
			}
			// {
			// 	label: 'Is D2R',
			// 	placeholder: '9999977777',
			// 	name: 'isD2R',
			// 	id: 'isD2R',
			// 	required: true,
			// 	fieldType: 'switch'
			// }
		];

		if (formTitle !== 'Create Warehouse User') {
			formFields = [...formFields, ...addressFields];
		}

		return formFields;
	};

	return (
		<FormLayout
			className={styles.form}
			formTitle={formTitle}
			isClose={false}
			onSave={onSave}
			formFields={generateFormFields()}
			customSubmitComponent={
				<Button type="primary" shape="round" size="large" htmlType="submit">
					{'Save'}
				</Button>
			}
		/>
	);
}

export function userColumns({ onClick = null, isDriver = false, isWarehouseUser = false, setAssignedOrderIds = null, setDriverId }) {
	const commonColumns = [
		{
			title: 'Name',

			render: (data) => {
				return (
					<div>
						{isDriver ? (
							<Button
								type="link"
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									if (onClick) {
										onClick(data.id);
									}
								}}
							>
								{data.name}
							</Button>
						) : (
							`${data.name}`
						)}
					</div>
				);
			}
		},
		{
			title: 'Phone',
			dataIndex: 'phone'
		},
		{
			title: isWarehouseUser ? 'Warehouse' : 'Cluster',
			dataIndex: isWarehouseUser ? 'warehouse' : 'cluster',
			render: (data) => {
				if (isWarehouseUser) {
					return data?.warehouseName;
				}
				return CLUSTER_MAPPING[data?.clusterName];
			}
		}
	];
	const statusColumn = [
		{
			title: 'Status',
			dataIndex: 'status',
			render: (status) => DRIVER_STATUS_MAPPING[status]
		},
		{
			title: 'Assigned Orders',
			render: (data) => (
				<Button
					disabled={Boolean(!data?.assignedOrderIds?.length)}
					onClick={() => {
						setAssignedOrderIds(data?.assignedOrderIds);
						setDriverId(data?.id);
					}}
					type="link"
				>
					Show Orders
				</Button>
			)
		}
	];
	if (isDriver) {
		return [...commonColumns, ...statusColumn];
	}
	return commonColumns;
}

export async function getUser({ setUser, getUser, userType }) {
	try {
		const { data } = await getUser();
		if (data.status) {
			setUser(
				data.data.map((user) => ({
					key: user.id,
					value: user.id,
					label: `${user.name} - ${user.phone}`,
					...user
				}))
			);
		} else throw new Error(`Couldn't get ${userType}`);
	} catch (error) {
		setUser([]);
		errorHandler(error);
	}
}
