export enum ISidebarTabs {
	RETAILERS = 'RETAILERS',
	INVOICES = 'INVOICES',
	SALESMEN = 'SALESMEN',
	RETAIL_DISCOUNT = 'RETAIL_DISCOUNT',
	BEATS = 'BEATS',
	EXTERNAL_RETAILERS = 'EXTERNAL_RETAILERS',
	RETAILER_ENGINE = 'RETAILER_ENGINE'
}

export const D2RsubSectionsMap = {
	[ISidebarTabs.RETAILERS]: '1',
	[ISidebarTabs.INVOICES]: '2',
	[ISidebarTabs.SALESMEN]: '3',
	[ISidebarTabs.RETAIL_DISCOUNT]: '4',
	[ISidebarTabs.BEATS]: '5',
	[ISidebarTabs.EXTERNAL_RETAILERS]: '6',
	[ISidebarTabs.RETAILER_ENGINE]: '7'
};

export const sidebarUtilD2r = {
	sideBar: [
		{
			key: '1',
			label: 'Retailers',
			icon: 'allSvgIcon',
			isNew: false
		},
		{
			key: '2',
			label: 'Invoices',
			icon: 'allSvgIcon',
			isNew: false
		},
		{
			key: '3',
			label: 'Salesmen',
			icon: 'allSvgIcon',
			isNew: false
		},
		{
			key: '4',
			label: 'Retail Discount',
			icon: 'allSvgIcon',
			isNew: false
		},
		{
			key: '5',
			label: 'Beats',
			icon: 'allSvgIcon',
			isNew: false
		},
		{
			key: '6',
			label: 'External Retailers',
			icon: 'allSvgIcon',
			isNew: false
		},
		{
			key: '7',
			label: 'Retailer Engine',
			icon: 'allSvgIcon',
			isNew: false
		}
	]
};
