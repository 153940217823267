import { keyValuePairForm } from './constant';
export const RetailerFormField = Object.keys(keyValuePairForm).map((key) => {
	return {
		name: [key],
		id: [key],
		label: keyValuePairForm[key],
		placeholder: `Enter ${keyValuePairForm[key]}`,
		fieldType: key === 'isActive' ? 'select' : '',
		options:
			key === 'isActive'
				? [
						{
							value: true,
							label: 'Active'
						},
						{
							value: false,
							label: 'InActive'
						}
				  ]
				: []
	};
});
