import React, { useContext, useState } from 'react';
import { Button, Col, Row, Input, Form, Select, Switch, Upload } from 'antd';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import { Loading } from 'shared/components/Loading';
import errorHandler from '../../utils/errorHandler';
import { AuthContext } from 'shared/contexts/Auth';
import { EUploadFileKey } from 'shared/api/misc';
import { getPresignedUrl } from 'utils/s3/getPresignedUrl';
const allowedImageFileTypes = /(\/jpg|\/jpeg|\/png|\/webp)$/i;
/**
 * Example: shared/components/CatalogueInfoSlider/FormFields
 * 
 * @param onSave (Submit Function)
 * @param onClose (Closing form Function) 
 * @param formTitle (Title Of the Form) 
 * @param initialValues (InitialValues object for the form fields) 
 * @param isClose (Should close button display or not) 
 * @param saveButtonText (Save button Text ) 
 * @param closeButtonText (Close button Text) 
 * @param customSubmitComponent (Component for custom submit option) 
 * @param formFields {
		name(String): Name Of the field,
		id(String): Id of the Field,
		label(String): Label Of the Field,
		required(Boolean): Field is compulsory or not,
		placeholder(String): Placeholder of the Field
		fieldType(String): If field is text then do not pass key but if field has select type the pass "select" value 
		options(Array): Option if field type is select : [
			{
				label(String): "Label of option",
				value(String): "Value of option"
			}
		]
		rules: Rules for Form.Item, It can be array or form validator function
		onSelectChange (onSelectChange function to listen change on selection)
 * }
 * 
 *  
 * @returns Form Layout
 * 
 */

export interface IFormLayout {
	formTitle: string;
	onSave: any;
	onClose: () => void;
	formFields: any;
	initialValues: any;
	isClose?: boolean;
	saveButtonText?: string;
	closeButtonText?: string;
	customSubmitComponent?: any;
	className?: string;
}

export function FormLayout({
	onSave,
	onClose,
	formFields,
	formTitle,
	initialValues,
	isClose = true,
	saveButtonText = 'Save',
	closeButtonText = 'Close',
	customSubmitComponent,
	className = ''
}: Readonly<IFormLayout>) {
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();
	const [imageUrl, setImageUrl] = useState(initialValues?.imageUrls ? initialValues?.imageUrls[0] : '');
	const {
		authState: { profile }
	} = useContext(AuthContext);

	const onFinish = async (values: any) => {
		try {
			setLoading(true);
			form.resetFields();
			await onSave(values);
		} catch (error) {
			console.log('Error:', error);
		} finally {
			setLoading(false);
		}
	};

	const renderElement = (formItem: any) => {
		// Custom Element
		if (formItem.fieldType === 'custom') {
			return <Col span={24}>{formItem.component}</Col>;
		}

		if (formItem.fieldType === 'image') {
			const imageProps = {
				name: 'file',
				beforeUpload: async (file: any) => {
					try {
						if (!file) return;
						if (!allowedImageFileTypes.exec(file.type)) {
							throw new Error('Only images are allowed');
						}

						try {
							const url = await getPresignedUrl(file, EUploadFileKey.PRODUCT_IMG, file.type, file.name, {
								companyId: profile.id
							});

							setImageUrl(url);
						} catch (error) {
							errorHandler(error);
						}
					} catch (error) {
						errorHandler(error);
					}

					return false;
				},
				onRemove: () => {
					setImageUrl(initialValues?.imageUrls[0]);
				},
				maxCount: 1
			};

			form.setFieldsValue({ imageUrls: [imageUrl] || initialValues.imageUrls });

			return (
				<Form.Item name={formItem.name} className={styles.formItem} style={{ width: '200px' }}>
					<img src={imageUrl} style={{ height: '100px', margin: '10px' }} alt="product-image" />
					<Upload {...imageProps}>
						<Button icon={<UploadOutlined />}>Change Image</Button>
					</Upload>
				</Form.Item>
			);
		}

		// Switch Element
		if (formItem.fieldType === 'switch') {
			return (
				<Col span={24}>
					<Form.Item name={formItem.name} className={styles.formItem} rules={setRules(formItem)}>
						<Switch
							checkedChildren="ON"
							unCheckedChildren="OFF"
							checked={formItem.value}
							onChange={(updatedStatus) => {
								formItem.onSelectChange(updatedStatus, form);
							}}
						/>
					</Form.Item>
				</Col>
			);
		}

		// Select Element
		if (formItem.fieldType === 'select') {
			return (
				<Col span={24}>
					<Form.Item name={formItem.name} className={styles.formItem} rules={setRules(formItem)}>
						<Select
							className={styles.select}
							placeholder={formItem.placeholder}
							allowClear={formItem.allowClear}
							showSearch={formItem.showSearch}
							optionFilterProp={formItem.optionFilterProp}
							filterOption={formItem.filterOption}
							disabled={formItem.disabled}
							onSelect={
								formItem.onSelectChange
									? (value: any) => {
											formItem?.onSelectChange(value, form);
									  }
									: undefined
							}
						>
							{formItem.options.map((optionItem: any) => {
								return (
									<Select.Option key={optionItem.value} value={optionItem.value}>
										{optionItem.label}
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>
			);
		}

		// Input element
		return (
			<Col span={24}>
				<Form.Item name={formItem.name} className={styles.formItem} rules={setRules(formItem)}>
					<Input
						placeholder={formItem.placeholder}
						size="large"
						className={styles.input}
						onChange={
							formItem.onInputChange
								? (value) => {
										formItem.onInputChange(value, form);
								  }
								: undefined
						}
					/>
				</Form.Item>
			</Col>
		);
	};

	const fieldTypeMessage = (formItem: any) => {
		if (formItem.fieldType === 'select') {
			return `Please select your ${formItem.label}!`;
		}

		return `Please enter your ${formItem.label}!`;
	};

	const setRules = (formItem: any) => {
		if (formItem.rules) {
			if (Array.isArray(formItem.rules)) {
				return [
					...formItem.rules,
					{
						required: formItem.required,
						message: fieldTypeMessage(formItem)
					}
				];
			}

			return [
				formItem.rules,
				{
					required: formItem.required,
					message: fieldTypeMessage(formItem)
				}
			];
		}

		return [
			{
				required: formItem.required,
				message: fieldTypeMessage(formItem)
			}
		];
	};

	return (
		<div className={`${className} ${styles.main}`}>
			{loading && <Loading />}
			{formFields && (
				<Col className={styles.orderInfoContainer}>
					<Row className={styles.row4}>
						<Col span={24}>
							<Row>
								<Col span={18}>
									<span className="title">{formTitle}</span>
								</Col>
								{isClose ? (
									<Col className={styles.icon} span={6}>
										<CloseOutlined
											onClick={(e) => {
												e.stopPropagation();
												onClose();
											}}
										/>
									</Col>
								) : null}
							</Row>
						</Col>
						<Col span={24} className="sub">
							<Form form={form} name="general_form" className={styles.form} initialValues={initialValues} onFinish={onFinish}>
								{formFields.map((formItem: any) =>
									Object.keys(formItem).length ? (
										<Row className="row4-row" key={formItem.id}>
											<Col span={24}>{formItem.label}</Col>
											{renderElement(formItem)}
										</Row>
									) : null
								)}
								{customSubmitComponent || (
									<Form.Item className={styles.btn}>
										<Button
											type="primary"
											shape="round"
											size="large"
											htmlType="submit"
											loading={loading}
											className={styles.button}
										>
											{saveButtonText}
										</Button>
										{isClose && (
											<Button
												type="primary"
												shape="round"
												size="large"
												className={styles.button}
												onClick={() => onClose()}
											>
												{closeButtonText}
											</Button>
										)}
									</Form.Item>
								)}
							</Form>
						</Col>
					</Row>
				</Col>
			)}
		</div>
	);
}
