import { apiOms } from '../../../../shared/api';

export const populateProductItems = (items, catalogData, pricingType, pricingValue) => {
	const updateProductObject = ({ id, gst, quantity, price, itemPricing, isFree }) => {
		const catalog = catalogData.find((catalog) => catalog.id === id);
		const updatedProductObject = {
			name: catalog.productTitle,
			variantId: catalog.variantId,
			productId: catalog.productId,
			quantity,
			weight: catalog.weight,
			sku: catalog.sku,
			//price: price ?? catalog.price,
			/**
			 *  for case when isFree is toggled from true to false ,
			 *  but price as retreived from backend is 0 - in that case set price to value present in catalog.price
			 */
			price: price === 0 && isFree === false ? catalog?.price : price ?? catalog?.price,
			gstRate: gst,
			discount: 0,
			catalogueId: id,
			pricingType,
			pricingValue: itemPricing ?? Number(pricingValue),
			basePrice: catalog?.basePrice ?? 0,
			isFree: isFree ?? false
		};

		return updatedProductObject;
	};

	const populatedProductItems = items.map(updateProductObject);
	return populatedProductItems;
};

export const fetchAddresses = (shipping, billing) => {
	const { firstName, lastName, phone, address1, address2, city, state, zip, email, GSTIN } = shipping;

	const shippingAddress = {
		GSTIN,
		firstName: firstName || '',
		email,
		address1,
		phone,
		city,
		pincode: zip,
		province: state,
		country: 'India',
		lastName: lastName || '',
		address2,
		company: '',
		latitude: 0,
		longitude: 0,
		countryCode: 'IN',
		provinceCode: ''
	};

	const billingAddressFormat = {
		...billing,
		firstName: billing?.firstName || '',
		pincode: billing?.zip,
		province: billing?.state,
		country: 'India',
		lastName: billing?.lastName || '',
		company: '',
		latitude: 0,
		longitude: 0,
		countryCode: 'IN',
		provinceCode: ''
	};

	const billingAddress = billing ? billingAddressFormat : { ...shippingAddress };

	const customer = {
		firstName: firstName || '',
		lastName: lastName || '',
		email,
		phone,
		address: {
			firstName: firstName || '',
			lastName: lastName || '',
			phone,
			address1,
			address2,
			city,
			state,
			country: 'India',
			pincode: zip,
			company: ''
		}
	};

	return { customer, shippingAddress, billingAddress };
};

export const createOrder = (formData) => {
	return apiOms.post('/order', formData);
};

export const createStoreAddress = (storeAddress) => {
	return apiOms.post('/d2r/store-address', storeAddress);
};

// get store addresses , with filter isActive , search by phone and store name

export const getStoreAddress = (companyId, searchText, isStoreName = false, searchOption = 'storeName') => {
	const storeNameParam = isStoreName ? `&searchText=${searchText}&searchOptionType=${searchOption}` : '';
	return apiOms.get(`/d2r/store-address/list/${companyId || ''}?limit=100${storeNameParam}&isActive=1`);
};

export const IManualOrderFormName = Object.freeze({
	SHIPPING_ADDRESS: 'shippingAddress',
	STORE_ADDRESS: 'storeAddress',
	D2R_WAREHOUSE_ID: 'd2rWarehouseId',
	BILLING_ADDRESS: 'billingAddress',
	SHIPPING_DETAILS: 'Shipping Details',
	STORE_ADDRESS_DETAILS: 'Store Details',
	BILLING_ADDRESS_HEADING: 'Billing Address',
	SALESMAN_DETAILS: 'Salesman Details'
});

export const IManualOrderTypes = Object.freeze({
	B2B_ORDER: 1,
	D2R_ORDER: 2
});

export const IManualOrderPricingTypes = Object.freeze({
	1: 'margin',
	2: 'discount',
	3: 'flatPrice'
});

export const getD2rOrderTotalPrice = (orderItems) => {
	let totalPrice = 0;

	for (const item of orderItems) {
		const { quantity, gstRate } = item;

		const GST = gstRate?.igst;

		if (Number(GST) < 0 || GST === null || isNaN(Number(GST))) {
			throw new Error('GST % is not available for this item, please add the same in catalog');
		}

		let rate = 0;
		let discountToBe = 0;
		let marginRate;
		switch (item.pricingType) {
			case 1:
				if (!item.basePrice) {
					throw new Error(`Base Price not exists for sku ${item.sku}`);
				}
				marginRate = item.pricingValue ?? item?.margin ?? 0;
				// item.pricingValue contains margin coming from Front End
				rate = item.basePrice + (item.basePrice * Number(marginRate)) / 100;
				break;
			case 2:
				rate = item.price / (1 + parseInt(String(GST), 10) / 100); // remove GST % from item's MRP
				discountToBe = (rate * Number(item.pricingValue ?? 0)) / 100;
				break;
			case 3:
				rate = item.pricingValue / (1 + parseInt(String(GST), 10) / 100); // Removing gst
				break;
			default:
				rate = item.price / (1 + parseInt(String(GST), 10) / 100);
		}

		if (item?.isFree) {
			rate = 0;
			discountToBe = 0;
		}

		const subTotal = rate * quantity;
		const totalDiscount = discountToBe * quantity;
		const total = subTotal - totalDiscount;
		const totalTax = (total * Number(GST)) / 100;
		const finalPrice = total + totalTax;

		totalPrice += finalPrice;
	}

	return totalPrice;
};
