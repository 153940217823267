import { Input, Form, Row, Col, Select, InputNumber, Checkbox, Button } from 'antd';
import { useState } from 'react';
import { indianStates } from 'constants/indianStates';
import { antDesignValidator } from '../../../../../../helper';
import styles from './index.module.scss';
import { getPincodeDetails } from 'shared/api/orders';
import { IManualOrderFormName } from '../../utils';

export const ManualOrderAddressForm = ({
	customStyle,
	editAddress,
	formName,
	handleBillingAddressForm = () => {},
	formHeading,
	onCreateNewStoreAddress,
	onCancelNewStoreAddress,
	isStoreAddressForm,
	shouldShowFullForm = true,
	existOrderData
}) => {
	const [isLoading, setIsLoading] = useState(false);
	let existAddressData = existOrderData ? existOrderData[formName] : null;

	if (!existAddressData && formName === IManualOrderFormName.SHIPPING_ADDRESS) {
		existAddressData = existOrderData?.shippingD2RAddress;
	}

	/**
	 * getWarehousePinDetails on change of pincode value
	 * @param {*} pincode
	 */
	const getWarehousePinDetails = async (pincode) => {
		try {
			// Pincode length should be 6
			if (pincode.length !== 6) return;

			// Pincode length greater than 6 then pincode and is invalid and city and state will ne in its initial state
			if (pincode.length > 6) {
				editAddress({
					state: undefined,
					city: ''
				});
				return;
			}

			setIsLoading(true);
			// Get pincode details
			let {
				data: { warehousePinDetails }
			} = await getPincodeDetails(pincode);

			if (warehousePinDetails[0]) {
				const state = String(warehousePinDetails[0]['state'] || warehousePinDetails[0]['State']).replace(/"/g, '');
				const city = String(warehousePinDetails[0]['district'] || warehousePinDetails[0]['District']).replace(/"/g, '');
				editAddress({
					state,
					city
				});

				return;
			}

			editAddress({
				state: undefined,
				city: ''
			});
		} catch (e) {
		} finally {
			setIsLoading(false);
		}
	};

	const handleAddNewStore = async () => {
		setIsLoading(true);
		try {
			await onCreateNewStoreAddress();
		} catch (err) {
			console.log(`error occured- ${err?.message}`);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className={`${styles.shippingAddress} ${customStyle}`}>
			<h4 className={styles.heading}>{formHeading}</h4>

			<h5 className={styles.subHeading}>Contact Person</h5>

			<Row gutter={24}>
				{isStoreAddressForm ? (
					<Col span={8}>
						<Form.Item
							label="Store Name"
							name={[formName, 'storeName']}
							rules={[{ required: true, message: 'Please input Store name' }]}
						>
							<Input placeholder="John" />
						</Form.Item>
					</Col>
				) : (
					<>
						<Col span={8}>
							<Form.Item
								label="First Name"
								name={[formName, 'firstName']}
								initialValue={existAddressData?.firstName}
								rules={[{ required: true, message: 'Please input First name' }]}
							>
								<Input placeholder="John" />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								label="Last Name"
								name={[formName, 'lastName']}
								initialValue={existAddressData?.lastName}
								rules={[{ required: true, message: 'Please input Last name' }]}
							>
								<Input placeholder="Wick" />
							</Form.Item>
						</Col>
					</>
				)}
				<Col span={8}>
					<Form.Item
						label="Phone (10 digits)"
						name={[formName, 'phone']}
						initialValue={existAddressData?.phone}
						rules={[{ required: true, message: 'Please input Phone Number' }, antDesignValidator.phoneNumber]}
					>
						<Input placeholder="9900097000" />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item label="Email" name={[formName, 'email']} initialValue={existAddressData?.email}>
						<Input placeholder="john.wick@mail.com" />
					</Form.Item>
				</Col>
			</Row>

			{shouldShowFullForm && (
				<>
					<h5 className={styles.subHeading}>Address</h5>
					<Row gutter={24}>
						<Col span={8}>
							<Form.Item
								label="Address Line 1"
								name={[formName, 'address1']}
								initialValue={existAddressData?.addressLine1}
								rules={[{ required: true, message: 'Please input Address Line 1' }]}
							>
								<Input placeholder="12/33 Abc Street" />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								label="Address Line 2"
								name={[formName, 'address2']}
								initialValue={existAddressData?.addressLine2}
								rules={[{ required: true, message: 'Please input Address Line 2' }]}
							>
								<Input placeholder="Karol Bagh" />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={8}>
							<Form.Item
								label="Pincode"
								name={[formName, 'zip']}
								initialValue={existAddressData?.pincode}
								rules={[{ required: true, message: 'Please input Pincode' }, antDesignValidator.pincode]}
							>
								<Input placeholder="600011" onChange={(event) => getWarehousePinDetails(event.target.value)} />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								label="City"
								initialValue={existAddressData?.city}
								name={[formName, 'city']}
								rules={[{ required: true, message: 'Please input City' }]}
							>
								<Input placeholder="Delhi" disabled={isLoading} />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								label="State"
								initialValue={existAddressData?.state}
								name={[formName, 'state']}
								rules={[{ required: true, message: 'Please input State' }]}
							>
								<Select disabled={isLoading} showSearch placeholder="Delhi">
									{indianStates.map(({ label, value }) => {
										return (
											<Select.Option value={value} key={value}>
												{label}
											</Select.Option>
										);
									})}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col>
							<Form.Item label="GSTIN" initialValue={existAddressData?.GSTIN} name={[formName, 'GSTIN']}>
								<Input placeholder="Optional" maxLength={15} minLength={15} />
							</Form.Item>
						</Col>
						{isStoreAddressForm && (
							<Col>
								<Form.Item
									initialValue={existAddressData?.isDistributor || false}
									valuePropName="checked"
									name={[formName, 'isDistributor']}
								>
									<Checkbox className={styles.Checkbox} style={{ marginTop: '35px' }} defaultChecked={false}>
										Is Distributor
									</Checkbox>
								</Form.Item>
							</Col>
						)}
					</Row>
				</>
			)}
			{onCreateNewStoreAddress && (
				<Row>
					<Button type="primary" onClick={handleAddNewStore} loading={isLoading}>
						Add
					</Button>
					<Button style={{ marginLeft: 10 }} onClick={onCancelNewStoreAddress}>
						Cancel
					</Button>
				</Row>
			)}

			{formName === IManualOrderFormName.SHIPPING_ADDRESS && (
				<Checkbox className={styles.Checkbox} onChange={handleBillingAddressForm} defaultChecked={true}>
					Billing address same as shipping address
				</Checkbox>
			)}

			{formName === IManualOrderFormName.SHIPPING_ADDRESS && (
				<>
					<h5 className={styles.subHeading}>Shipping Charge</h5>
					<Row gutter={24}>
						<Col span={8}>
							<Form.Item initialValue={existOrderData?.shippingPrice} name="shippingPrice" label="Amount">
								<InputNumber min={0} placeholder="100" />
							</Form.Item>
						</Col>
					</Row>
				</>
			)}
		</div>
	);
};
