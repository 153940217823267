import axios from 'axios';
import { getS3Link } from '../../shared/api/misc';

export const getPresignedUrl = async (file, key, filetype, filename, metadata) => {
	const {
		data: { url: presignedUrl }
	} = await getS3Link(key, filetype, filename, metadata);

	await axios.put(presignedUrl, file, {
		headers: {
			'Content-Type': filetype
		}
	});

	const resultingS3Path = presignedUrl.split('?')[0];
	return resultingS3Path;
};
