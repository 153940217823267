import { Row, Col } from 'antd';
import { useEffect, useState } from 'react';
import { Sidebar } from 'shared/components/AppLayout/Sidebar';
import { getLightningSubPageMapper, getUser, sidebarUtil } from './utils';
import { CLUSTER_MAPPING } from './constants';
import { getClusters, getAllDrivers, getWarehouses } from 'shared/api/lightning';
import errorHandler from 'shared/utils/errorHandler';
import { SideDrawer } from '../../../shared/components/SideDrawer/SideDrawer';

export const lightningRoute = '/admin/lightning';

export function Lightning() {
	const [sidebarMenuItem, setSidebarMenuItem] = useState(1);
	const [clusters, setClusters] = useState([]);
	const [drivers, setDrivers] = useState([]);
	const [warehouses, setWarehouses] = useState([]);

	const LIGHTNING_SUB_PAGE_MAPPER = getLightningSubPageMapper({ clusters, setClusters, drivers, setDrivers, warehouses });

	async function getAllClusters() {
		try {
			const { data } = await getClusters();
			if (data.status) {
				setClusters(
					data.data.map((cluster) => {
						return {
							key: cluster.id,
							value: cluster.id,
							label: CLUSTER_MAPPING[cluster.clusterName],
							...cluster
						};
					})
				);
			} else throw new Error(`Couldn't get clusters`);
		} catch (error) {
			setClusters([]);
			errorHandler(error);
		}
	}

	async function getAllWarehouses() {
		try {
			const { data } = await getWarehouses();
			if (data.status) {
				setWarehouses(
					data.data.map((warehouse) => {
						return {
							key: warehouse.id,
							value: warehouse.id,
							label: warehouse.name,
							...warehouse
						};
					})
				);
			} else throw new Error(`Couldn't get warehouses`);
		} catch (error) {
			setWarehouses([]);
			errorHandler(error);
		}
	}

	useEffect(() => {
		getAllClusters();
		getAllWarehouses();
		getUser({
			setUser: setDrivers,
			getUser: getAllDrivers,
			userType: 'drivers'
		});
	}, []);

	return (
		<Row className="h-100 p-0">
			<SideDrawer placement="left" isTrackingLayout={5}>
				<Sidebar
					sideBarMenu={sidebarUtil.sideBar}
					bottomButtons={null}
					selectedMenu={sidebarMenuItem}
					onMenuItemSelect={setSidebarMenuItem}
				/>
			</SideDrawer>
			<Col sm={24} lg={19} className={`scrollable`}>
				{LIGHTNING_SUB_PAGE_MAPPER[sidebarMenuItem]}
			</Col>
		</Row>
	);
}
