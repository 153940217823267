import { envs } from './env';

interface IFormatCoordinate {
	coordinates: number[];
}

export function formatCoordinate(coordinates: IFormatCoordinate) {
	return [
		{
			lat: coordinates?.coordinates?.[0] || 0,
			lng: coordinates?.coordinates?.[1] || 0,
			text: 'Warehouse'
		}
	];
}

export const isSuperAdmin = (profile: any) => profile?.name === 'admin' && envs.appType === 'AppAdmin';
