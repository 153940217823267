import { Modal } from 'antd';
import { useState } from 'react';
import { DEFAULT_IMAGE_IN_CATALOGUE } from 'shared/utils/constant';

export function ImageViewer({ imageUrls }: any) {
	const [showModal, setShowModal] = useState(false);

	const closeModal = () => {
		setShowModal(false);
	};

	const openModal = () => {
		setShowModal(true);
	};

	return (
		<>
			<img
				src={imageUrls.length ? imageUrls[0] : DEFAULT_IMAGE_IN_CATALOGUE}
				style={{ height: 50 }}
				alt={imageUrls.length ? 'image' : 'default'}
				onClick={openModal}
			/>
			<Modal visible={showModal} title="View Image" onOk={closeModal} onCancel={closeModal} width={'60vw'}>
				<div style={{ textAlign: 'center', userSelect: 'none' }}>
					<img
						src={imageUrls.length ? imageUrls[0] : DEFAULT_IMAGE_IN_CATALOGUE}
						style={{ maxHeight: '70vh', maxWidth: '100%' }}
						alt={imageUrls.length ? 'image' : 'default'}
					/>
				</div>
			</Modal>
		</>
	);
}
