import { FulfillmentProviders } from '../../constants';

export const getTrackingUrl = (provider: string, trackingID: string) => {
	const providerToURLMap: any = {
		[FulfillmentProviders.XPREESSBEES]: [`https://www.xpressbees.com/track?isawb=Yes&trackid=${trackingID}`],
		[FulfillmentProviders.DTDC_PRIORITY]: [`https://www.dtdc.in/tracking.asp`],
		[FulfillmentProviders.DTDC_SMART_EXPRESS]: [`https://www.dtdc.in/tracking.asp`],
		[FulfillmentProviders.DELHIVERYEXPRESS]: [`https://www.delhivery.com/track/package/${trackingID}`],
		[FulfillmentProviders.DELHIVERYSURFACE]: [`https://www.delhivery.com/track/package/${trackingID}`],
		[FulfillmentProviders.GOSWIFT]: [`https://app.goswift.in/track/${trackingID}`],
		[FulfillmentProviders.WHEREHOUSE_LIGHTNING]: [
			document.URL.includes('localhost')
				? `http://localhost:3000/track/${trackingID}`
				: document.URL.includes('staging')
				? `https://wherehouse-seller-staging.web.app/track/${trackingID}`
				: `https://brands.wherehouse.io/track/${trackingID}`
		]
	};

	return providerToURLMap[provider];
};
