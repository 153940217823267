import { useEffect, useState } from 'react';
import { apiOms } from '../../../../shared/api';
import errorHandler from 'shared/utils/errorHandler';
import styles from './index.module.scss';
import { Button, Pagination } from 'antd';
import { RequestNewAddressModal } from './components/RequestNewAddress';
import { AddressCard } from './components/AddressCard';
import { defaultFilter, searchOptions } from '../utils';
import { DebouncedCompactSearch } from '../../../../shared/components/DebouncedCompactSearch/DebouncedCompactSearch';
import { RetailerInfoSlider } from 'shared/components/RetailerInfoSlider/RetailerInfoSlider';
import { obtainProfileData } from '../../../../shared/api/profile';
export function Address() {
	const [address, setAddress] = useState([]);
	const [isRequestNewAddressModalVisible, setIsRequestNewAddressModalVisible] = useState(false);
	const [addressCount, setAddressCount] = useState(0);
	const [pageConfig, setPageConfig] = useState({ current: 1, pageSize: 10 });
	const [filter, setFilter] = useState(defaultFilter);
	const [isRetailerModalVisible, setIsRetailerModalVisible] = useState(false);
	const [retailerId, setRetailerId] = useState('');
	const [reloadRetailerList, setReloadRetailerList] = useState(0);
	const [company, setComapny] = useState<any>('');

	const searchParamConstruct = () => {
		const searchParam = new URLSearchParams();
		const { searchOptionType, searchValue } = filter;

		if (searchOptionType && searchValue) {
			searchParam.append('searchOptionType', searchOptionType);
			searchParam.append('searchText', searchValue);
		}
		searchParam.append('page', pageConfig.current + '');
		searchParam.append('limit', pageConfig.pageSize + '');
		return searchParam;
	};

	const fetchStoreAddress = async () => {
		try {
			const {
				data: { responseBody }
			} = await obtainProfileData();

			if (responseBody?.isD2RAccount !== '1') {
				return;
			}

			const searchParamVal = searchParamConstruct();
			const {
				data: { storeAddressData, totalRecords }
			} = await apiOms.get(`/d2r/store-address/list/?${searchParamVal.toString()}`);

			setAddress(storeAddressData);
			setAddressCount(totalRecords);
			setComapny(storeAddressData?.companyId);
		} catch (error) {
			errorHandler(error);
		}
	};

	useEffect(() => {
		fetchStoreAddress();
	}, [pageConfig, filter, reloadRetailerList]);

	useEffect(() => {
		console.log(`retailer modal visible value- ${isRetailerModalVisible}`);
	}, [isRetailerModalVisible]);

	return (
		<div className={styles.addressPage}>
			<div className={styles.wrapperDiv}>
				<RequestNewAddressModal
					fetchStoreAddress={fetchStoreAddress}
					isModalVisible={isRequestNewAddressModalVisible}
					setIsModalVisible={setIsRequestNewAddressModalVisible}
				/>
				<div className={styles.container}>
					<div className={styles.searchContainer}>
						<span>Search By: </span>
						<DebouncedCompactSearch
							className={styles.compactSearchContainer}
							defaultSearchOptionType={filter.searchOptionType}
							searchOptions={searchOptions}
							setFilter={setFilter}
							debounceTime={700}
							setDefault={() => setPageConfig({ current: 1, pageSize: 10 })}
						></DebouncedCompactSearch>
					</div>
					<Button
						className={styles.actionButtonsContainer}
						size="large"
						type="primary"
						onClick={() => setIsRequestNewAddressModalVisible(true)}
					>
						Add New Store Address
					</Button>
				</div>

				<div className={styles.addressList}>
					{address.length ? (
						address.map((addressCurrent: any, index: number) => {
							return (
								<AddressCard
									key={addressCurrent?.id}
									setIsRetailerModalVisible={setIsRetailerModalVisible}
									setRetailerId={setRetailerId}
									address={{ ...addressCurrent, index: pageConfig.pageSize * (pageConfig.current - 1) + index + 1 }}
								/>
							);
						})
					) : (
						<div>
							<h6>No Store Address</h6>
						</div>
					)}
				</div>
			</div>
			<div className={styles.paginationContainer}>
				<Pagination
					className={styles.pagination}
					defaultCurrent={1}
					current={pageConfig.current}
					total={addressCount}
					pageSize={pageConfig.pageSize}
					showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
					onChange={(current, pageSize) => setPageConfig((v) => ({ ...v, current, pageSize }))}
					showSizeChanger
					pageSizeOptions={[10, 50, 100, 200, 300]}
				/>
				{isRetailerModalVisible && (
					<RetailerInfoSlider
						isRetailerModalVisible={isRetailerModalVisible}
						retailerData={address.filter((R: any) => R.id === retailerId)}
						setIsRetailerModalVisible={setIsRetailerModalVisible}
						company={company}
						setReloadRetailerList={setReloadRetailerList}
					/>
				)}
			</div>
		</div>
	);
}
