import { TaskCenterAdmin } from '../../../shared/components/TaskCenter/TaskCenterAdmin';
import { Preferences } from '../../../shared/components/Preferences';
import { Orders } from './Orders';
import { Products } from './Products';
import Warehouses from './Warehouses';
import { WhatsAppService } from './WhatsAppService';
import { WSIN } from './WSIN';

const sideBarArray = ['Orders', 'Products', 'WSIN', 'Warehouses', 'Preferences', 'Whatsapp Service', 'TaskCenterAdmin'];

export const sidebarUtil = {
	sideBar: sideBarArray.map((item, index) => ({ key: index + 1, label: item, icon: 'allSvgIcon' }))
};

export function getBrandsSubPageMapper({ brand, setAdminCatalogueSelectedKeys, adminCatalogueSelectedKeys }) {
	return {
		1: <Orders brand={brand} />,
		2: (
			<Products
				brand={brand}
				setAdminCatalogueSelectedKeys={setAdminCatalogueSelectedKeys}
				adminCatalogueSelectedKeys={adminCatalogueSelectedKeys}
			/>
		),
		3: <WSIN brand={brand} setAdminCatalogueSelectedKeys={setAdminCatalogueSelectedKeys} />,
		4: <Warehouses brand={brand} />,
		5: <Preferences isAdmin={true} companyId={brand.id} />,
		6: <WhatsAppService brand={brand} />,
		7: <TaskCenterAdmin isAdmin={true} companyId={brand.id} />
	};
}
