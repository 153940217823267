import { Col } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { apiCms } from '../../../../shared/api';
import { TopFilterComponent } from '../../../../shared/components/AppLayout/TopFilterComponent';
import { CustomTablePagination } from '../../../../shared/components/CustomTablePagination';
import { Loading } from '../../../../shared/components/Loading';
import { UploadBulkModal } from '../../../../shared/components/UploadBulkModal';
import { bulkD2rDiscountPreviewRoute, MAX_ROWS_EXCEED_ERROR_MSG } from '../../../../shared/components/UploadBulkPreview/constants';
import errorHandler from '../../../../shared/utils/errorHandler';
import { getStoreAddress } from '../../../AppSeller/Orders/ManualOrder/utils';
import { d2rDiscountPricingColumn, discountFilters } from './utils';
import { companyType } from '../D2r';

export const Discount = ({ company }: { company: companyType }) => {
	const [isUploadExcelModalVisible, setIsUploadExcelModalVisible] = useState(false);
	const [catalogues, setCatalogues] = useState([]);
	const [storeAddress, setStoreAddress] = useState([]);
	const [loading, setLoading] = useState(false);
	const [storeId, setStoreId] = useState<any>(null);

	const topFilterConfig = useMemo(
		() =>
			discountFilters({
				company,
				setIsUploadExcelModalVisible,
				storeAddress,
				setStoreId,
				storeId,
				setCatalogues
			}),
		[company, storeAddress, storeId]
	);
	const columnsConfig = useMemo(() => d2rDiscountPricingColumn(), []);

	const getCatalogues = useCallback(async () => {
		if (!storeId) {
			return;
		}
		const { data } = await apiCms.get(`/catalogue/pricing/${company.id}/${storeId}`);
		if (data?.status) {
			setCatalogues(data?.data);
		}
	}, [company.id, storeId]);

	const getStoreAddresses = useCallback(async () => {
		const {
			data: { storeAddressData }
		} = await getStoreAddress();
		setStoreAddress(storeAddressData);
	}, []);

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			await getCatalogues();
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	}, [getCatalogues]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		getStoreAddresses();
	}, [getStoreAddresses]);

	if (loading) {
		return <Loading loading={true} />;
	}

	return (
		<>
			<Col sm={24} lg={24} span={24} className="main">
				<TopFilterComponent {...topFilterConfig} />
				<div className="innerDiv">
					<div className="div w-100 h-100">
						<CustomTablePagination
							columns={columnsConfig}
							data={catalogues}
							loading={loading}
							shouldRowSelection={false}
							selectedItem={undefined}
							catalog={undefined}
							selectedRowKeys={undefined}
							setSelectedRowKeys={undefined}
							setSelectedItem={undefined}
							showPagination={false}
						/>
					</div>
				</div>
			</Col>
			<UploadBulkModal
				instructionMessage={
					<ul className="instructions">
						<li>Download the excel template by clicking "Download Sample" button below</li>
						<li>Edit file with WSIN data and upload </li>
						<br />
						Note: {MAX_ROWS_EXCEED_ERROR_MSG}
					</ul>
				}
				templatePath={process.env.REACT_APP_EXCEL_WSIN_TEMPLATE}
				previewPath={bulkD2rDiscountPreviewRoute}
				modalTitle="Bulk Catalogue Discount Upload"
				isModalVisible={isUploadExcelModalVisible}
				setIsModalVisible={setIsUploadExcelModalVisible}
				companyId={company.id}
				shopId={storeId}
				shouldShowDownloadSample={false}
			/>
		</>
	);
};
