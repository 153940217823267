import { useState } from 'react';
import { Button, Col, Drawer, Row, notification } from 'antd';

import styles from './index.module.scss';
import { apiOms } from 'shared/api';
import { companyType } from 'app/AppAdmin/D2R/D2r';
import { FormLayout } from '../FormLayout/FormLayout';
import { RetailerFormField } from './RetailerFormField';
import { keyValuePairForm } from './constant';
export interface IPropsReatilerSliderInfo {
	isRetailerModalVisible: boolean;
	retailerData: any;
	setIsRetailerModalVisible: Function;
	company: companyType;
	setReloadRetailerList?: Function;
}

export function RetailerInfoSlider({
	isRetailerModalVisible,
	retailerData,
	setIsRetailerModalVisible,
	company,
	setReloadRetailerList
}: Readonly<IPropsReatilerSliderInfo>) {
	const [isEditRetailFormVisible, setIsEditRetailFormVisible] = useState<boolean>(false);
	const handleCloseDrawer = () => {
		setIsRetailerModalVisible(false);
	};

	const onFinish = async (values: any) => {
		try {
			const { data } = await apiOms.patch(`/d2r/retailers`, { retailerId: retailerData[0].id, ...values });
			if (data?.status) {
				notification.success({
					message: 'Success',
					description: data?.message,
					placement: 'topRight'
				});
			}
		} catch (error: any) {
			const msg: string = error?.message || 'Something went wrong';
			notification.error({
				message: 'Failed',
				description: msg,
				placement: 'topRight'
			});
		} finally {
			setReloadRetailerList && setReloadRetailerList((prev: number) => prev + 1);
			setIsEditRetailFormVisible(false);
		}
	};
	return (
		<Drawer visible={isRetailerModalVisible} onClose={handleCloseDrawer}>
			{retailerData ? (
				<Row>
					{isEditRetailFormVisible ? (
						<div className={styles.editForm}>
							<FormLayout
								formTitle={'Edit Retailer Details'}
								onSave={(values: any) => onFinish(values)}
								onClose={() => setIsEditRetailFormVisible(false)}
								formFields={RetailerFormField}
								initialValues={{
									...Object.keys(keyValuePairForm).reduce((acc: any, key: string) => {
										return {
											...acc,
											[key]: retailerData[0]?.[key]
										};
									}, {})
								}}
							/>
						</div>
					) : (
						<Row style={{ top: '20px' }}>
							<Col span={24} className={styles.retailerTitle}>
								<Row className={styles.row}>
									<Col span={18}>Retailer Information</Col>
									<Col span={6}>
										<Button
											style={{ fontSize: '15px', borderRadius: '5px' }}
											type="primary"
											onClick={() => setIsEditRetailFormVisible(true)}
										>
											Edit
										</Button>
									</Col>
								</Row>
							</Col>
							<Col span={24} className={styles.retailerInfo}>
								<Row className={styles.row}>
									<Col className={styles.col} span={11}>
										<span className={styles.headingText}>Store Name:</span>
										<span>{retailerData[0]?.firstName + retailerData[0]?.lastName}</span>
									</Col>
								</Row>
								<Row className={styles.row}>
									<Col className={styles.col} span={11}>
										<span className={styles.headingText}>City:</span>
										<span>{retailerData[0]?.city}</span>
									</Col>
									<Col className={styles.col} span={11}>
										<span className={styles.headingText}>State:</span>
										<span>{retailerData[0]?.state}</span>
									</Col>
								</Row>
								<Row className={styles.row}>
									<Col className={styles.col} span={11}>
										<span className={styles.headingText}>Pincode:</span>
										<span>{retailerData[0]?.pincode}</span>
									</Col>
									<Col className={styles.col} span={11}>
										<span className={styles.headingText}>Country:</span>
										<span>{retailerData[0]?.country}</span>
									</Col>
								</Row>

								<Row className={styles.row}>
									<Col className={styles.col} span={11}>
										<span className={styles.headingText}>Email:</span>
										<span>{retailerData[0]?.email}</span>
									</Col>
									<Col className={styles.col} span={11}>
										<span className={styles.headingText}>Phone:</span>
										<span>{retailerData[0]?.phone}</span>
									</Col>
								</Row>
								<Row className={styles.row}>
									<Col className={styles.col} span={11}>
										<span className={styles.headingText}>Address Line 1:</span>
										<span>{retailerData[0]?.addressLine1}</span>
									</Col>
									<Col className={styles.col} span={11}>
										<span className={styles.headingText}>Address Line 2:</span>
										<span>{retailerData[0]?.addressLine2 || '-'}</span>
									</Col>
								</Row>
								<Row className={styles.row}>
									<Col className={styles.col} span={11}>
										<span className={styles.headingText}>GSTIN:</span>
										<span>{retailerData[0]?.GSTIN || '-'}</span>
									</Col>
									<Col className={styles.col} span={11}>
										<span className={styles.headingText}>Address Purpose:</span>
										<span>{retailerData[0]?.addressPurpose || '-'}</span>
									</Col>
								</Row>
								<Row className={styles.row}>
									<Col className={styles.col} span={11}>
										<span className={styles.headingText}>Status:</span>
										<span>{`${retailerData[0].isActive ? 'Active' : 'InActive'}`}</span>
									</Col>
								</Row>
							</Col>
						</Row>
					)}
				</Row>
			) : (
				<></>
			)}
		</Drawer>
	);
}
