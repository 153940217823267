export const sidebarUtil = {
	sideBar: [
		{
			key: '1',
			label: 'Profile',
			icon: 'user',
			isNew: false
		},
		{
			key: '2',
			label: 'Integrations',
			icon: 'integrate',
			isNew: false
		},
		{
			key: '3',
			label: 'Task Center',
			icon: 'download',
			isNew: false
		},
		{
			key: '4',
			label: 'Preferences',
			icon: 'preference',
			isNew: false
		},
		// {
		// 	key: '4',
		// 	label: 'Pickup Address',
		// 	icon: 'retailer'
		// },
		{
			key: '6',
			label: 'Wherehouses',
			icon: 'location',
			isNew: false
		},
		{
			key: '7',
			label: 'storeAddress',
			icon: 'retailer',
			isNew: false
		},
		{
			key: '8',
			label: 'Invoicing',
			icon: 'invoicing',
			isNew: false
		},
		{
			key: '9',
			label: 'Tracking Page',
			icon: 'tracking',
			isNew: false
		},
		{
			key: '10',
			label: 'Shipping Packages',
			icon: 'shipping',
			isNew: false
		},
		{
			key: '11',
			label: 'Wallet',
			icon: 'wallet',
			isNew: false
		},
		{
			key: '12',
			label: 'API',
			icon: 'api',
			isNew: false
		},
		{
			key: '13',
			label: 'Support',
			icon: 'support',
			isNew: false
		}
	]
};
interface ISearchOption {
	value: string;
	label: string;
}

export const searchOptions: ISearchOption[] = [
	{ value: 'storeName', label: 'store name' },
	{ value: 'phone', label: 'phone' }
];

interface IFilterType {
	searchOptionType: string;
	searchValue: any;
}

export const defaultFilter: IFilterType = {
	searchOptionType: searchOptions[0].value,
	searchValue: null
};
