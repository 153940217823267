import { FulfillmentProviders } from '../../../../../constants';

// Delivery Partner Body should be all lower case
export const deliveryPartnersWithBody = [
	FulfillmentProviders.GOSWIFT,
	FulfillmentProviders.PICKRR,
	FulfillmentProviders.SHIPROCKET,
	FulfillmentProviders.SHIPWAY,
	FulfillmentProviders.SHYPLITELITE,
	FulfillmentProviders.SHYPLITEAIR,
	FulfillmentProviders.SHYPLITESURFACE,
	FulfillmentProviders.CLICKPOST,
	FulfillmentProviders.DELHIVERYSURFACE,
	FulfillmentProviders.DELHIVERYEXPRESS,
	FulfillmentProviders.BLOWHORN,
	FulfillmentProviders.BLUEDART,
	FulfillmentProviders.XPREESSBEES,
	FulfillmentProviders.I_THINK_LOGISTICS,
	FulfillmentProviders.BLITZ,
	FulfillmentProviders.SHIPERFECTO,
	FulfillmentProviders.SHIPDELIGHT,
	FulfillmentProviders.DTDC_SMART_EXPRESS,
	FulfillmentProviders.DTDC_PRIORITY
];

export const deliveryPartnerFields = {
	[FulfillmentProviders.GOSWIFT]: [
		{ label: 'Client ID', name: 'clientId' },
		{ label: 'Username', name: 'username' },
		{ label: 'Password', name: 'password' }
	],
	[FulfillmentProviders.PICKRR]: [{ label: 'Auth Token', name: 'password' }],
	[FulfillmentProviders.DELHIVERYSURFACE]: [
		{ label: 'Auth Token', name: 'password' },
		{ label: 'Client', name: 'clientId' }
	],
	[FulfillmentProviders.DELHIVERYEXPRESS]: [
		{ label: 'Auth Token', name: 'password' },
		{ label: 'Client', name: 'clientId' }
	],
	[FulfillmentProviders.SHIPROCKET]: [
		{ label: 'Email', name: 'email' },
		{ label: 'Password', name: 'password' }
	],
	[FulfillmentProviders.SHIPWAY]: [
		{ label: 'Email', name: 'email' },
		{ label: 'Licence key', name: 'licenceKey' }
	],
	[FulfillmentProviders.SHYPLITELITE]: [
		{ label: 'Seller ID', name: 'clientId' },
		{ label: 'App ID', name: 'appId' },
		{ label: 'Public Key', name: 'username' },
		{ label: 'App Secret', name: 'password' }
	],
	[FulfillmentProviders.SHYPLITESURFACE]: [
		{ label: 'Seller ID', name: 'clientId' },
		{ label: 'App ID', name: 'appId' },
		{ label: 'Public Key', name: 'username' },
		{ label: 'App Secret', name: 'password' }
	],
	[FulfillmentProviders.SHYPLITEAIR]: [
		{ label: 'Seller ID', name: 'clientId' },
		{ label: 'App ID', name: 'appId' },
		{ label: 'Public Key', name: 'username' },
		{ label: 'App Secret', name: 'password' }
	],
	[FulfillmentProviders.CLICKPOST]: [
		{ label: 'UserName', name: 'username' },
		{ label: 'Seller ID', name: 'clientId' }
	],
	[FulfillmentProviders.BLUEDART]: [
		{ label: 'Login Id', name: 'loginId' },
		{ label: 'Origin Area', name: 'originArea' },
		{ label: 'Shipping License key', name: 'shippingLicenseKey' },
		{ label: 'Customer Code', name: 'customerCode' },
		{ label: 'Tracking License key', name: 'trackingLicKey' },
		{ label: 'Tracking Login Id', name: 'trackingLoginId' }
	],
	[FulfillmentProviders.BLOWHORN]: [
		{ label: 'Email', name: 'email' },
		{ label: 'Password', name: 'password' }
	],
	[FulfillmentProviders.XPREESSBEES]: [
		{ label: 'Username', name: 'username' },
		{ label: 'Password', name: 'password' },
		{ label: 'Secret key', name: 'secretkey' },
		{ label: 'XB Key', name: 'XBkey' },
		{ label: 'Reverse XB Key', name: 'reverseXBkey' },
		{ label: 'Business Name', name: 'businessName' }
	],
	[FulfillmentProviders.I_THINK_LOGISTICS]: [
		{ label: 'Access Token', name: 'accessToken' },
		{ label: 'Secret Key', name: 'secretkey' }
	],
	[FulfillmentProviders.BLITZ]: [
		{ label: 'Username', name: 'username' },
		{ label: 'Password', name: 'password' }
	],
	[FulfillmentProviders.SHIPERFECTO]: [
		{ label: 'Client ID', name: 'clientId' },
		{ label: 'Username', name: 'username' },
		{ label: 'Password', name: 'password' }
	],
	[FulfillmentProviders.SHIPDELIGHT]: [{ label: 'Password', name: 'password' }],
	[FulfillmentProviders.DTDC_SMART_EXPRESS]: [
		{ label: 'Customer Code', name: 'clientId' },
		{ label: 'Api Key', name: 'password' },
		{ label: 'Access Token', name: 'accessToken' },
		{ label: 'Tracking User Name', name: 'trackingUserName' },
		{ label: 'Tracking User Password', name: 'trackingUserPwd' }
	],
	[FulfillmentProviders.DTDC_PRIORITY]: [
		{ label: 'Customer Code', name: 'clientId' },
		{ label: 'Api Key', name: 'password' },
		{ label: 'Access Token', name: 'accessToken' },
		{ label: 'Tracking User Name', name: 'trackingUserName' },
		{ label: 'Tracking User Password', name: 'trackingUserPwd' }
	]
};
